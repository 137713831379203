import { type Link } from '../../api-models'
import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

/**
 * Given a product id, returns all the data needed to render the technical specification modal
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/technicalSpecifications/operation/getTechnicalSpecifications}
 */
export const getTechnicalSpecifications =
  createHttpEndpoint<GetTechnicalSpecificationsResponse>({
    method: 'GET',
    operationId: 'getTechnicalSpecifications',
    path: '/bm/product/:productId/technical_specifications',
  })

export type GetTechnicalSpecificationsResponse = HttpResponsePayload<{
  title: string
  warnings: Array<{
    title: string
    texts: Array<string>
    hyperlink?: {
      text: string
      link: Link
    }
  }>
  providedWithProduct: {
    title: string
    texts: Array<string>
    hyperlink?: {
      text: string
      link: Link
    }
    rows: Array<{
      text: string
      icon: 'iphone' | 'charging_cable'
    }>
  }
  comesWith: {
    displayAccessoryExclusionsModal: boolean
    items: Array<{
      text: string
      type: string
      icon: 'IconBattery' | 'IconHeart' | 'IconChargingWire'
    }>
  }
  reassurances: Array<{
    title: string
    texts: Array<string>
  }>
  specifications: Array<{
    field: string
    display: string
    values: Array<{
      label: string
    }>
  }>
  additionalInfo: Array<string>
}>
